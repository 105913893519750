<template>

  <div class="pb text-style-info">
    <h1>Settings</h1>


    {{ useExperimentsDiaryWide().value ? 1 : 0 }}

    {{ experimentsDiaryWide }}

    <div>
      <UiToggle 
        @update:modelValue="switchExperimentsDiaryWide"
        :label="'Wide diaries'"
        v-model="experimentsDiaryWide" 
        />
    </div>


  </div>

</template>

<script setup>

const { $api, $head } = useNuxtApp();
 
const experimentsDiaryWide = ref(useExperimentsDiaryWide().value ? 1 : 0)

const switchExperimentsDiaryWide = (val) => {
  console.log('val:')
  console.log(val)
  experimentsDiaryWide.value = val
  useExperimentsDiaryWide().value = val ? true : false

}

</script>

<style scoped>

.text-style-info{
    text-indent: 2rem;
}

.html_into :v-deep(.de_im){
  height: 14px;
  vertical-align: middle;
}
 
 
</style>